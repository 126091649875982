.StripeElement {
  padding: 2px 5px;
  width: 100%;
  color: #32325d;
  background-color: white;
  border: 1px solid #d2d6dc;
  border-radius: 4px;

  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}
